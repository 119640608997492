<template>
  <section class="manage-integrations">
    <h1 class="manage-integrations-title">
      {{ t('title') }}
    </h1>
    <div class="card">
      <gs-back />
      <p class="manage-integrations-description">
        {{ t('description') }}
      </p>
      <integrations-table @select="onSelect" />
    </div>
    <div v-if="!isIntegrator" class="card card--with-button">
      <div class="text-wrapper">
        <h3 class="add-integration-title">
          {{ isCustom ? t('add_source_title') : t('add_integration_title') }}
        </h3>

        <p class="add-integraion-description">
          {{
            isCustom
              ? t('add_source_description')
              : t(
                  'add_integration_description',
                  selectedPlatform
                    ? {
                        platform: getIntegrationPlatformName(selectedPlatform),
                      }
                    : {},
                )
          }}
          <button class="chat-button" @click="openChat()">
            {{ t('get_in_touch') }}
          </button>
        </p>
      </div>
      <gs-button size="large" href="/add-integrations-automations?addNew=true">
        {{ isCustom ? t('add_source') : t('add_integration') }}
      </gs-button>
    </div>
  </section>
</template>

<script lang="ts">
import IntegrationsTable from '@/components/integration/IntegrationsTable.vue'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { includes } from '@/helpers/parsers'
import type { IntegrationPlatform } from '@/helpers/interfaces'
import { CUSTOM_SOURCES, INTEGRATOR_SOURCES } from '@/helpers/interfaces'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'
import type { IntegrationTableData } from '@/store/integrations'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ManageIntegrationsView',
  components: { IntegrationsTable },
  mixins: [LoadCrispMixin, IntegrationsMixin],
  computed: {
    selectedPlatform(): IntegrationPlatform {
      return this.$route.query.platform as IntegrationPlatform
    },
    isCustom(): boolean {
      return includes(CUSTOM_SOURCES, this.selectedPlatform)
    },
    isIntegrator(): boolean {
      return includes(INTEGRATOR_SOURCES, this.selectedPlatform)
    },
  },
  created() {
    this.toggleChatBubbleVisibility(true)
  },
  methods: {
    t(key: string, params?: { platform?: string }) {
      return this.$t(`ManageIntegrationsView.${key}`, params ?? {})
    },
    onSelect(integration: IntegrationTableData) {
      this.$router.push({
        name: 'AddIntegrationsAndAutomations',
        query: { integrationId: integration.id },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.manage-integrations {
  background: url('../../assets/backgrounds/manage-ingetrations-automations.png') no-repeat
    center/cover;
  height: 100%;
  min-height: 100vh;
  padding: 20px;
}

.manage-integrations-title {
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 20px;
}

.manage-integrations-description {
  font-size: 18px;
  line-height: 24px;
  max-width: 40ch;
  margin-bottom: 20px;
}

.card {
  padding: 15px;
  background: white;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  max-width: 1080px;
  margin: 0 auto 20px;
}

.add-integration-title {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 20px;
}

.add-integraion-description {
  font-size: 18px;
  line-height: 24px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .manage-integrations {
    padding: 40px;
  }

  .manage-integrations-title {
    margin-bottom: 40px;
  }

  .card {
    padding: 40px;
    margin-bottom: 40px;
  }

  .card.card--with-button {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .chat-button {
    color: var(--ui-green);
    text-decoration: underline;
    font-size: 16px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>
