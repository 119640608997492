<template>
  <section class="email-integration-sync-alert">
    <button @click="navigateBack" class="close-button" />
    <onboarding-panel
      :title="t('title')"
      :description="t('description')"
      background-color="transparent"
      :is-skippable="isOnboarding"
      :on-skip="skipDataSync"
      :skip-button-text="t('skip')"
    >
      <div class="image-wrapper">
        <img :src="require('@/assets/images/email-data-sync-flow.svg')" :alt="t('title')" />
      </div>
      <p class="select-integration-title">{{ t('select_integration') }}</p>
      <select-integration
        @integration-selected="selectIntegration"
        @close="clearIntegration"
        hide-search
        active-category="email"
        :integration-option-selection="getOptionsForSelectionList"
      />
      <div class="button-wrapper">
        <gs-button
          type="primary"
          size="large"
          full-width
          :disabled="!getSelectedIntegrationForDataSync"
          :loading="loading"
          @click.prevent="next"
        >
          {{ $t('CommonUi.next') }}
        </gs-button>
        <v-checkbox
          v-if="!isCustomerEngagementPlatform"
          v-model="isDismissed"
          color="green"
          :label="t('dismiss')"
        ></v-checkbox>
      </div>
    </onboarding-panel>
  </section>
</template>

<script lang="ts">
import SelectIntegration from '@/components/onboarding/SelectIntegration.vue'
import type { IntegrationOption, IntegrationTableData } from '@/store/integrations'
import type { SelectedIntegration } from '@/components/onboarding/SelectIntegration.vue'
import OnboardingPanel from '@/components/onboarding/OnboardingPanel.vue'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'EmailIntegrationAlertView',
  components: {
    OnboardingPanel,
    SelectIntegration,
  },
  data() {
    return {
      loading: false,
    } as {
      loading: boolean
    }
  },
  async created() {
    await this.fetchIntegrationOptions()
  },
  computed: {
    integrationList(): IntegrationTableData[] {
      return this.getIntegrationsForTable()
    },
    getIntegrationsForTable(): () => IntegrationTableData[] {
      return this.$store.getters['getIntegrationsForTable']
    },
    integrationOptionList(): IntegrationOption[] {
      return this.$store.getters['getIntegrationOptionList']
    },
    getOptionsForSelectionList(): IntegrationOption[] {
      return this.integrationOptionList
        .filter((integration) => integration.writeDirection)
        .sort((a, b) => {
          return Number(b?.writeDirection?.isAvailable) - Number(a?.writeDirection?.isAvailable)
        })
    },
    getSelectedIntegrationForDataSync(): SelectedIntegration {
      return this.$store.getters['getSelectedIntegrationForDataSync']
    },
    isDismissed: {
      get(): boolean {
        return this.$store.getters['getEmailSyncAlertDismissed']
      },
      set(value: boolean) {
        this.setEmailSyncAlertDismissed(value)
      },
    },
    isOnboarding(): boolean {
      return this.$route.query.from === 'onboarding'
    },
    isCustomerEngagementPlatform(): boolean {
      return this.$route.query.from === 'engagement'
    },
  },
  methods: {
    fetchIntegrationOptions(): Promise<void> {
      return this.$store.dispatch('fetchIntegrationOptions')
    },
    setEmailSyncAlertDismissed(isDismissed: boolean): void {
      return this.$store.commit('setEmailSyncAlertDismissed', isDismissed)
    },
    setSelectedIntegrationForDataSync(
      integration: SelectedIntegration | IntegrationTableData | undefined,
    ) {
      return this.$store.commit('setSelectedIntegrationForDataSync', integration)
    },
    t(key: string): TranslateResult {
      return this.$t(`EmailIntegrationAlertView.${key}`)
    },
    clearIntegration() {
      if (this.getSelectedIntegrationForDataSync) {
        this.setSelectedIntegrationForDataSync(undefined)
      }
    },
    async selectIntegration(item) {
      await this.fetchIntegrationOptions()

      this.setSelectedIntegrationForDataSync(
        this.integrationList.find(
          (integration) => integration.platform.toLowerCase() === item.name.toLowerCase(),
        ),
      )
    },
    async next() {
      if (!this.getSelectedIntegrationForDataSync) {
        return
      }

      await this.$router.push({
        name: 'EmailDataSync',
        query: { ...this.$route.query },
      })
    },
    skipDataSync() {
      this.$router.push({
        path: '/',
        query: { ...this.$route.query },
      })
    },
    navigateBack() {
      if (this.isOnboarding) {
        this.skipDataSync()
      } else {
        this.$router.push({ name: 'Automations' })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.email-integration-sync-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  min-height: 100vh;
  background: url('@/assets/backgrounds/full-width-popup-background.svg') no-repeat top left/cover
    #ffffff;
  padding: 20px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 80px 40px;
  }
}

:deep(.select-integration) {
  padding: 16px 0;
}

:deep(.onboarding-panel__title) {
  padding-right: 60px;
}

:deep(.onboarding-panel__description) {
  max-width: 100%;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  margin: 32px 0 24px;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--gray-light-CC);

  img {
    max-width: 100%;
  }
}

.close-button {
  position: absolute;
  right: 25px;
  top: 20px;
  width: 30px;
  height: 30px;
}

.select-integration-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}

.button-wrapper {
  margin-top: 16px;
}
</style>
