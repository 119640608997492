<template>
  <div class="select-integration">
    <div v-if="!hideSearch" class="search-menu-wrapper">
      <gs-input
        id="search-input"
        ref="searchInput"
        v-model="searchValue"
        :placeholder="t('placeholder')"
        append-inner-icon="mdi-magnify"
        class="search-field"
        type="search"
        @blur="
          () => {
            isMenuOpen = false
            fetchIntegrationOptions(searchValue)
          }
        "
      />
      <transition name="fade">
        <ul v-if="isMenuOpen" class="search-menu-list">
          <li v-for="(item, index) in searchItems" :key="index" class="search-menu-item">
            <button class="search-menu-item-button" @click="handleAutoSuggestClick(item.value)">
              <span class="match-text" v-html="item.text" />
            </button>
          </li>
        </ul>
      </transition>
    </div>

    <div v-if="!hideSearch" class="filter-button-list">
      <button
        v-for="(category, index) in integrationCategories"
        :key="index"
        :class="['filter-button', { 'is-active': categoryActiveIndex === index }]"
        @click="searchByCategory(category, index)"
      >
        {{ t(category) }}
      </button>
    </div>

    <LoaderBlock v-if="areIntegrationsLoading" background-color="white" />

    <div
      v-else-if="!areIntegrationsLoading && integrationOptionList.length"
      class="integration-list"
    >
      <button
        v-for="(integration, index) in getOptionsForSelectionList"
        v-show="showIntegration(integration)"
        :key="index"
        :class="[
          'integration-button',
          { 'is-active': activeIndex === index },
          { 'is-integrated': integration.activeNumber },
          { planned: integration.status === 'planned' },
        ]"
        :disabled="
          integration.status === 'planned' || isIntegrationEmailSyncCompatible(integration)
        "
        @click="handleClick(integration, index)"
      >
        <v-icon
          v-if="integration.slug === 'custom' && !integration.icon"
          class="integration-logo placeholder"
        >
          mdi-puzzle-outline
        </v-icon>
        <img v-else :src="integration.icon" alt="" class="integration-logo" />

        <span>{{ integration.name }}</span>
        <span
          v-if="integration.activeNumber && !isIntegrationEmailSyncCompatible(integration)"
          class="active-marker active-marker--filled"
        >
          {{ getIfPlatformCustom(integration) ? '' : integration.activeNumber }} {{ t('active') }}
        </span>
        <span v-if="isIntegrationEmailSyncCompatible(integration)" class="active-marker">
          {{ t('coming_soon') }}
        </span>
      </button>
    </div>
    <div v-else class="no-result">
      <p class="no-result__text">
        {{ t('no_result', { searchValue: searchValue }) }}
      </p>
      <gs-button size="large" @click.prevent="openRequestPopup"> Request integration </gs-button>
    </div>
    <ShopifyIntegrationAlert v-model="isShopifyAlertPopupOpen" />
    <RebuyIntegrationPopup v-model:is-open="isRebuyPopupOpen" />
    <EtsyIntegrationAlert v-model="isEtsyAlertPopupOpen" @submit="handleClickContinue" />
    <CallToUpgradePopup
      v-if="isCallToUpgradePopupOpen"
      :is-premium-only="isPremiumOnly"
      :popup-type="popupType"
      :integrator-type="integratorType || undefined"
      @close="isCallToUpgradePopupOpen = false"
    />
    <loading v-if="loading" />
  </div>
</template>

<script lang="ts">
import GsInput from '@/components/form/gs-input.vue'
import type { IntegrationCategory } from '@/helpers/constants'
import { GREENSPARK_SHOPIFY_MARKETPLACE_URL, INTEGRATION_CATEGORIES } from '@/helpers/constants'
import { includes } from '@/helpers/parsers'
import LoaderBlock from '@/components/tools/LoaderBlock.vue'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'
import ShopifyIntegrationAlert from '@/components/integration/ShopifyIntegrationAlert.vue'
import RebuyIntegrationPopup from '@/components/integration/RebuyIntegrationPopup.vue'
import type { Notification } from '@/store/notification'
import type { Account } from '@/store'
import type { CallToUpgradePopupType } from '@/components/common/CallToUpgradePopup.vue'
import CallToUpgradePopup from '@/components/common/CallToUpgradePopup.vue'
import Loading from '@/components/tools/Loading.vue'
import type { Integration, IntegrationOption } from '@/store/integrations'
import type { DialogUpdatePayload } from '@/store/dialog'
import { Dialogs } from '@/store/dialog'
import type {
  AutomationTrigger,
  Emptyable,
  IntegrationPlatform,
  IntegratorSource,
} from '@/helpers/interfaces'
import { STORE_TYPES } from '@/helpers/interfaces'
import { CUSTOM_INTEGRATION_TYPES } from '@/helpers/interfaces'
import { INTEGRATOR_SOURCES } from '@/helpers/interfaces'
import EtsyIntegrationAlert from '@/components/integration/EtsyIntegrationAlert.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export interface SelectedIntegration extends Integration {
  icon: string
  activeNumber: number
  provider: {
    name: string
    externalId: string
  }
  category: string
  triggers: AutomationTrigger[]
}
export default defineComponent({
  name: 'SelectIntegration',
  emits: ['integration-selected', 'close'],
  components: {
    EtsyIntegrationAlert,
    Loading,
    CallToUpgradePopup,
    ShopifyIntegrationAlert,
    RebuyIntegrationPopup,
    LoaderBlock,
    GsInput,
  },
  mixins: [IntegrationsMixin],
  data() {
    return {
      searchValue: '',
      areIntegrationsLoading: true,
      isMenuOpen: false,
      isShopifyAlertPopupOpen: false,
      isRebuyPopupOpen: false,
      isEtsyAlertPopupOpen: false,
      isCallToUpgradePopupOpen: false,
      isPremiumOnly: false,
      integratorType: '',
      popupType: 'integration',
      timer: null,
      categoryActiveIndex: null,
      activeIndex: null,
      searchableItems: [],
      searchItems: [],
      selectedCategory: '',
      selectedIntegration: {} as SelectedIntegration,
    } as {
      searchValue: string
      areIntegrationsLoading: boolean
      isMenuOpen: boolean
      isShopifyAlertPopupOpen: boolean
      isRebuyPopupOpen: boolean
      isEtsyAlertPopupOpen: boolean
      isCallToUpgradePopupOpen: boolean
      isPremiumOnly: boolean
      integratorType: Emptyable<IntegratorSource>
      popupType: CallToUpgradePopupType
      timer: null | ReturnType<typeof setTimeout>
      categoryActiveIndex: number | null
      activeIndex: number | null
      searchableItems: { text: string; value: string }[]
      searchItems: { text: string; value: string }[]
      selectedCategory: IntegrationCategory
      selectedIntegration: SelectedIntegration
    }
  },
  computed: {
    integrationCategories(): string[] {
      let returnValue = INTEGRATION_CATEGORIES
      if (this.integrators) {
        returnValue = returnValue.filter((category) => category !== 'integration')
      }
      if (!this.purchaseAndAutomationsApi && this.$route.name === 'Onboarding') {
        returnValue = returnValue.filter((category) => category !== 'custom')
      }
      if (!this.integrationsAmount) {
        returnValue = returnValue.filter((category) => category !== 'active')
      }
      return returnValue
    },
    destinationURL(): string {
      return this.$route.path
    },
    integrationOptionList(): IntegrationOption[] {
      return this.$store.getters['getIntegrationOptionList']
    },
    integrationList(): Integration[] {
      return this.$store.getters['getIntegrationList']
    },
    purchaseAndAutomationsApi(): boolean {
      return this.$store.getters['getPurchaseAndAutomationsApiFeatureSetting']
    },
    integrators(): boolean {
      return this.$store.getters['getIntegratorsFeatureSetting']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    maxActiveIntegrationSources(): number {
      return this.$store.getters['getMaxActiveIntegrationSources']
    },
    integrationsAmount(): number {
      return this.$store.getters['getIntegrationsAmount']
    },
    getOptionsForSelectionList(): IntegrationOption[] {
      return this.integrationOptionSelection?.length
        ? this.integrationOptionSelection
        : this.$store.getters['getOptionsForSelectionList']
    },
    isShopifyActive(): boolean {
      return this.$store.getters['getIsShopifyActive']
    },
    getIntegrationsByPlatform(): (platform: IntegrationPlatform) => Integration[] {
      return this.$store.getters['getIntegrationsByPlatform']
    },
  },
  async created() {
    this.loading = true
    await this.mountHotGlue()
    await this.fetchIntegrationOptions()
    this.setSearchableItems()
    if (this.$route.query.shopify || this.$route.query.directSignup)
      this.navigateToNextStep(
        this.getOptionsForSelectionList.find(({ slug }) => slug === 'shopify') as IntegrationOption,
      )
    if (this.activeCategory)
      this.searchByCategory(
        this.activeCategory,
        INTEGRATION_CATEGORIES.findIndex((category) => category === this.activeCategory),
      )
    this.areIntegrationsLoading = false
    this.loading = false

    if (this.integrationOptionSelection?.length) {
      const hasActiveIntegration = (option: IntegrationOption) => option.activeNumber === 1
      const activeIntegration = this.integrationOptionSelection.find(hasActiveIntegration)
      const activeIntegrationIndex = this.integrationOptionSelection.findIndex(hasActiveIntegration)

      if (activeIntegration) {
        this.activeIndex = activeIntegrationIndex
        this.$emit('integration-selected', activeIntegration)
      }
    }
  },
  methods: {
    integrationIsDisabled(integration: IntegrationOption): boolean {
      return integration.status === 'planned'
    },
    async handleClickContinue() {
      const etsyIntegrationOption = this.getOptionsForSelectionList.find(
        ({ slug }) => slug === 'etsy',
      ) as IntegrationOption
      await this.setupStore(
        etsyIntegrationOption,
        this.navigateToNextStep,
        this.handleIntegrationPopupClose,
      )
    },
    async searchByCategory(category: IntegrationCategory, index: number) {
      if (this.categoryActiveIndex === index) return
      this.searchValue = ''
      this.categoryActiveIndex = index
      this.selectedCategory = category
      switch (category) {
        case 'all':
          await this.fetchIntegrationOptions()
          break
        default:
          await this.fetchIntegrationOptions(category)
      }
    },
    async handleClick(integrationOption: IntegrationOption, index: number) {
      this.activeIndex = index

      if (integrationOption.slug === 'rebuy') {
        this.isRebuyPopupOpen = true
        return
      }

      // if the user has reached the limit of integrations and is trying to add a new one we show the upgrade popup
      const isAmountExceeded = this.integrationsAmount >= this.maxActiveIntegrationSources
      const isCustomIntegrationForbidden =
        integrationOption.slug === 'custom' && !this.purchaseAndAutomationsApi
      const isIntegratorAmountExceeded =
        !!integrationOption.activeNumber && includes(INTEGRATOR_SOURCES, integrationOption.slug)
      const isIntegrator = includes(INTEGRATOR_SOURCES, integrationOption.slug)
      const isIntegratorAdditionForbidden =
        isIntegrator && (isIntegratorAmountExceeded || !this.integrators)

      if (
        (isAmountExceeded || isCustomIntegrationForbidden || isIntegratorAdditionForbidden) &&
        (!integrationOption.activeNumber || this.$route.query.addNew)
      ) {
        this.selectedIntegration = {} as SelectedIntegration
        this.activeIndex = null
        this.isPremiumOnly = this.integrationsAmount >= 3 || isCustomIntegrationForbidden
        this.isCallToUpgradePopupOpen = true
        this.integratorType = integrationOption.slug as IntegratorSource
        this.setPopupType(integrationOption)
      } else if (
        integrationOption.activeNumber &&
        this.$route.name === 'Integrations' &&
        !this.$route.query.addNew
      ) {
        await this.$router.push({
          name: 'ManageIntegrations',
          query: { platform: integrationOption.slug },
        })

        // if the user is on the onboarding page we assume that he is adding a new integration, so we navigate to the next step
      } else if (integrationOption.activeNumber && this.$route.name === 'Onboarding') {
        this.navigateToNextStep(integrationOption)
        // if the user selected a custom integration or integrator integration we navigate to the next step
      } else if (
        (integrationOption.activeNumber && !this.$route.query.addNew) ||
        includes(CUSTOM_INTEGRATION_TYPES, integrationOption.provider.name)
      ) {
        this.navigateToNextStep(integrationOption, true)
      } else {
        try {
          if (integrationOption.slug === 'shopify' && !this.isShopifyActive) {
            // if user wants to add his/her first shopify store, we show the shopify alert popup which redirects to the shopify marketplace
            this.isShopifyAlertPopupOpen = true
            // if it's not the first shopify store we redirect to the shopify marketplace
          } else if (integrationOption.slug === 'shopify' && this.isShopifyActive) {
            window.open(GREENSPARK_SHOPIFY_MARKETPLACE_URL, '_self')
            // based on the integration option slug we decide which setup function to call (hotlgue or rutter)
          } else if (STORE_TYPES.find((t) => t === integrationOption.slug)) {
            if (integrationOption.slug === 'etsy') {
              this.isEtsyAlertPopupOpen = true
            } else {
              await this.setupStore(
                integrationOption,
                this.navigateToNextStep,
                this.handleIntegrationPopupClose,
              )
            }
          } else {
            this.loading = true
            this.setupIntegration(
              this.account?.accountId,
              integrationOption,
              this.navigateToNextStep,
              this.handleIntegrationPopupClose,
              this.handleIntegrationPopupOpen,
            )
          }
        } catch (e) {
          console.error(e)
          this.selectedIntegration = {} as SelectedIntegration
          this.activeIndex = null
          this.$store.dispatch('notification/notify', {
            text: this.$t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification)
        }
      }
    },
    navigateToNextStep(integrationOption: IntegrationOption, isCustomIntegration = false) {
      this.loading = false
      const latestIntegration: Integration =
        this.getIntegrationsByPlatform(integrationOption.slug)[
          this.getIntegrationsByPlatform(integrationOption.slug).length - 1
        ] || ({} as Integration)

      this.selectedIntegration = {
        ...latestIntegration,
        platform: integrationOption.slug,
        icon: integrationOption.icon,
        activeNumber: integrationOption.activeNumber,
        provider: integrationOption.provider,
        category: integrationOption.category,
        triggers: integrationOption.triggers as AutomationTrigger[],
      }
      if (isCustomIntegration) {
        this.$emit('integration-selected', {
          ...integrationOption,
          platform: integrationOption.provider.name,
        })
      } else {
        this.$emit('integration-selected', this.selectedIntegration)
      }
    },
    handleIntegrationPopupClose(): void {
      this.loading = false
      this.activeIndex = null
      this.$emit('close')
    },
    handleIntegrationPopupOpen(): void {
      this.loading = false
    },
    handleAutoSuggestClick(value: string) {
      this.searchValue = value
      this.isMenuOpen = false
    },
    setSearchableItems() {
      this.searchableItems = this.getOptionsForSelectionList.map((integration) => {
        return {
          text: integration.name,
          value: integration.slug,
        }
      })
    },
    t(key: string, params?: { [key: string]: string }) {
      return this.$t(`SelectIntegration.${key}`, params ?? {})
    },
    showIntegration(integration: IntegrationOption) {
      // non-custom integrations are filtered on the backend side so show them
      if (integration.provider?.name !== 'custom') return true
      // prevent showing custom integrations under all category and for users who have insufficient plan
      return (
        (!this.selectedCategory || ['all', 'active', 'custom'].includes(this.selectedCategory)) &&
        (this.$route.name === 'Onboarding' ? this.purchaseAndAutomationsApi : true)
      )
    },
    openRequestPopup() {
      this.openDialog({
        name: Dialogs.CUSTOM.REQUEST_INTEGRATION,
        options: {
          persistent: false,
        },
      })
    },
    setPopupType(integrationOption: IntegrationOption) {
      if (
        integrationOption.slug === 'custom' ||
        (!this.integrators && includes(INTEGRATOR_SOURCES, integrationOption.slug))
      ) {
        this.popupType = 'generic'
      } else if (
        !!integrationOption.activeNumber &&
        includes(INTEGRATOR_SOURCES, integrationOption.slug)
      ) {
        this.popupType = 'integrator'
      } else {
        this.popupType = 'integration'
      }
    },
    getIfPlatformCustom(integration: IntegrationOption) {
      return integration.tags?.includes('custom')
    },
    async onSearchValueChange() {
      this.categoryActiveIndex = null
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(async () => {
        if (this.searchValue.length > 2 && this.searchValue) {
          this.areIntegrationsLoading = true
          const regx = new RegExp(`(${this.searchValue})`, 'ig')

          this.searchItems = this.searchableItems
            .filter(({ value, text }) => value.match(regx) || text.match(regx))
            .map(({ value, text }) => {
              return {
                value,
                text: text.replace(regx, '<b>$1</b>'),
              }
            })
          this.isMenuOpen =
            !!this.searchItems.length && this.searchItems[0].value !== this.searchValue
          await this.fetchIntegrationOptions(this.searchValue)
          this.areIntegrationsLoading = false
        } else if (this.categoryActiveIndex === null) {
          await this.fetchIntegrationOptions()
          this.searchItems = []
        }
      }, 200)
      this.categoryActiveIndex = null
    },
    fetchIntegrationOptions(searchTerm?: string): Promise<void> {
      return this.$store.dispatch('fetchIntegrationOptions', searchTerm)
    },
    openDialog(dialog: DialogUpdatePayload): Promise<void> {
      return this.$store.dispatch('openDialog', dialog)
    },
    isIntegrationEmailSyncCompatible(integration: IntegrationOption) {
      return (
        this.$route.name === 'EmailIntegrationSyncAlert' &&
        integration.writeDirection &&
        !integration.writeDirection.isAvailable
      )
    },
  },
  watch: {
    searchValue: [
      {
        handler: 'onSearchValueChange',
      },
    ],
  },
  props: {
    hideSearch: {
      type: Boolean,
    },
    activeCategory: {
      type: String as PropType<IntegrationCategory>,
    },
    integrationOptionSelection: {
      type: Array as PropType<IntegrationOption[]>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.select-integration {
  padding: 15px;
  width: 100%;
}

.search-menu-wrapper {
  margin: 0 auto 15px;
  max-width: 500px;
  position: relative;
}

.filter-button-list {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.filter-button {
  border-radius: var(--border-radius-big);
  padding: 5px 8px;
  font-size: 16px;
  line-height: 20px;
  background-color: var(--ui-beige);
}

.filter-button.is-active {
  background-color: var(--ui-green);
  color: var(--ui-beige);
  cursor: auto;
}

.filter-button.is-hidden {
  display: none;
  visibility: hidden;
  height: 0;
  width: 0;
}

.integration-list {
  width: 100%;
  display: grid;
  gap: 20px 12px;
  grid-template-columns: repeat(1, 1fr);
}

.integration-button {
  width: 100%;
  border-radius: var(--border-radius-big);
  border: 2px solid var(--gray-light);
  padding: 20px 25px;
  display: flex;
  align-items: center;
  position: relative;

  &:disabled {
    cursor: not-allowed;
    background-color: var(--gray-light-F5);
    border-color: var(--gray-light-F5);
  }
}

.integration-button:not(:disabled):hover,
.integration-button.is-active {
  border: 2px solid var(--ui-green);
  box-shadow: var(--box-shadow);
}

.integration-button.planned::after {
  content: 'Coming soon';
  padding: 0 5px;
  font-size: 14px;
  line-height: 18px;
  top: 13px;
  right: 13px;
  border-radius: 15px;
  border: 2px solid var(--ui-green);
  color: var(--ui-green);
  position: absolute;
}

.active-marker {
  padding: 0 5px;
  font-size: 14px;
  line-height: 18px;
  top: 13px;
  right: 13px;
  border-radius: 15px;
  background-color: transparent;
  border: 1px solid var(--ui-green);
  color: var(--ui-green);
  font-weight: 600;
  position: absolute;

  &--filled {
    background-color: var(--ui-green);
    color: var(--ui-beige);
  }
}

.integration-logo {
  height: 50px;
  width: 50px;
  object-fit: contain;
  margin-right: 25px;
}

.integration-logo.placeholder {
  background: url('../../assets/backgrounds/icon-background.png') center/contain no-repeat;
  color: var(--ui-green);
  font-size: 32px;
}

.search-menu {
  background-color: white;
}

.search-menu-list {
  width: 100%;
  background-color: white;
  list-style-type: none;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-small);
  z-index: 1;
}

.search-menu-item {
  width: 100%;
}

.search-menu-item:not(:last-of-type) {
  border-bottom: 1px solid var(--gray-light);
}

.search-menu-item-button {
  padding: 10px 15px;
  font-size: 16px;
  width: 100%;
  text-align: left;
}

.search-menu-item-button:hover {
  background-color: var(--ui-green-light-20);
}

.match-text:deep(b) {
  color: var(--ui-green);
  background-color: var(--ui-green-light-20);
}

.no-result {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-result__text {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  max-width: 430px;
  margin-bottom: 20px;
}

.no-result__text::before,
.no-result__text::after {
  content: '';
  display: block;
  flex-shrink: 0;
  height: 65px;
  width: 65px;
}

.no-result__text::before {
  background: url('../../assets/reath-left.svg') no-repeat center/cover;
}

.no-result__text::after {
  background: url('../../assets/reath-right.svg') no-repeat center/cover;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .select-integration {
    padding: 25px 15px;
  }

  .integration-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width > 1038px) {
  .integration-button {
    padding: 15px 25px;
  }

  .filter-button-list {
    margin-bottom: 40px;
  }

  .select-integration {
    padding: 32px 24px 24px;
  }

  .integration-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
