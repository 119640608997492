<template>
  <Loading v-if="loading" />
  <section
    v-else-if="selectedIntegration && selectedIntegrationOptions?.writeDirection && !loading"
    class="email-data-sync-section"
  >
    <button @click="navigateBack" class="close-button" />
    <onboarding-panel
      class="email-data-sync"
      :title="t('title', { emailProvider: capitalizeFirstLetter(selectedIntegration?.platform) })"
      :description="t('description')"
      background-color="transparent"
    >
      <div
        class="email-data-sync-toggle"
        :class="{
          active: syncData,
          error:
            testConnection.status === 'error' &&
            testConnection.errorCode === INTEGRATION_WRITE_DIRECTION_TEST_FORBIDDEN,
        }"
      >
        <p class="email-data-sync-toggle__description">
          {{
            t('sync_toggle.description', {
              emailProvider: capitalizeFirstLetter(selectedIntegration?.platform),
            })
          }}
        </p>
        <v-switch
          v-model="syncData"
          @change="handleEmailSync"
          class="email-data-sync-toggle__switch"
          :label="t('sync_toggle.title')"
          hide-details
          inset
          color="green"
        >
        </v-switch>
        <small class="email-data-sync-toggle__disclaimer">{{ t('sync_toggle.disclaimer') }}</small>
      </div>
      <p
        v-if="
          testConnection.status === 'error' &&
          testConnection.errorCode === INTEGRATION_WRITE_DIRECTION_TEST_FORBIDDEN
        "
        @click="handleIntegrationUpdate"
        class="error-alert"
      >
        {{ t('test_connection.error.update_key') }}
      </p>
      <div class="email-data-sync-list">
        <p class="email-data-sync-list__title mb-4">{{ t('sync_list.title') }}</p>
        <div
          class="email-data-sync-list__options"
          v-for="(syncedProp, key) in syncedProperties"
          :key="key"
        >
          <p class="email-data-sync-list__title">{{ t(`sync_list.${key}`) }}</p>
          <ul class="list">
            <li v-for="(item, index) in syncedProp" :key="index" class="list__item">
              <div class="list__item-text">
                <v-icon color="light-green" size="18">mdi-check</v-icon>
                <span>{{ t(`sync_list.fields.${item}`) }}</span>
              </div>
              <onboarding-pill disabled>{{ item }}</onboarding-pill>
            </li>
          </ul>
        </div>
        <a
          :href="selectedIntegrationOptions.writeDirection.documentationLink"
          target="_blank"
          class="email-data-sync-list__link"
        >
          {{ t('sync_list.documentation_link') }}
        </a>
      </div>
      <div class="email-data-sync-test">
        <h2 class="email-data-sync-test__title">{{ t('test_connection.title') }}</h2>
        <p>
          {{
            t('test_connection.description', {
              emailProvider: capitalizeFirstLetter(selectedIntegration?.platform),
            })
          }}
        </p>
        <p class="email-data-sync-test__disclaimer">
          {{
            t('test_connection.event.disclaimer', {
              emailProvider: capitalizeFirstLetter(selectedIntegration?.platform),
            })
          }}
        </p>
        <ul v-if="syncedTestData" class="list">
          <li v-for="(value, key) in syncedTestData" :key="key" class="list__item">
            <div class="list__item-text">
              <v-icon color="green" size="18">mdi-check</v-icon>
              <span>{{ t(`test_connection.event.${key}`, { value }) }}</span>
            </div>
          </li>
        </ul>
        <gs-button
          @click="handleTestConnection"
          class="mt-6"
          type="primary"
          :loading="testConnection.loading"
          :disabled="testConnection.loading"
          size="large"
          full-width
          capitalized
          :uppercased="false"
        >
          {{ t('test_connection.status.default') }}
        </gs-button>
        <p
          v-if="
            testConnection.status === 'error' &&
            testConnection.errorCode === INTEGRATION_WRITE_DIRECTION_TEST_FORBIDDEN
          "
          @click="handleIntegrationUpdate"
          class="error-alert"
        >
          {{ t('test_connection.error.update_key') }}
        </p>
        <p v-if="testConnection.date" class="timestamp">
          {{ t('test_connection.date', { date: testConnectionTimestamp }) }},
          <template v-if="testConnection.status">
            {{ t('test_connection.status.title') }}
            <span :class="`email-data-sync-test__connection--${testConnection.status}`">
              {{
                testConnection.status === 'success'
                  ? t('test_connection.status.success')
                  : t('test_connection.status.error')
              }}
            </span>
          </template>
        </p>
      </div>
    </onboarding-panel>
    <onboarding-panel
      class="email-data-sync-historical"
      :class="{ disabled: hasOngoingHistoricalSync }"
      :title="t('historical_data.title')"
      :description="
        t('historical_data.description', {
          emailProvider: capitalizeFirstLetter(selectedIntegration?.platform),
        })
      "
      background-color="transparent"
    >
      <v-checkbox
        v-model="syncHistory.confirm"
        color="green"
        hide-details
        :label="t('historical_data.confirm')"
        :disabled="hasOngoingHistoricalSync"
      >
      </v-checkbox>
      <gs-button
        class="mt-2"
        type="primary"
        size="large"
        @click="handleSyncHistory"
        :disabled="
          hasOngoingHistoricalSync || !syncHistory.confirm || syncHistory.loading || !syncData
        "
        full-width
        capitalized
        :uppercased="false"
      >
        {{ t('historical_data.sync') }}
      </gs-button>
      <p v-if="syncHistory.date && !syncHistory.loading" class="timestamp">
        {{ t('historical_data.date', { date: syncHistoryTimestamp }) }}
        <template v-if="syncHistory.count !== null">
          , {{ t('historical_data.impact_actions') }}
          {{ formatNumberByLocale(syncHistory.count ?? 0) }}
        </template>
      </p>
      <p
        v-if="
          syncHistory.loading ||
          (!syncHistory.date && selectedIntegration.writeDirection?.syncHistory)
        "
        class="success-alert"
      >
        <v-icon color="#47d18f">mdi-check-circle</v-icon>
        {{ t('historical_data.in_progress') }}
      </p>
      <p v-if="syncHistory.errorCode" class="error-alert">
        {{ t(`historical_data.error.${syncHistory.errorCode}`) }}
      </p>
    </onboarding-panel>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'

import { formatDateToUTC } from '@/helpers/parsers'
import { isDemoEnvironment } from '@/helpers/constants'
import type {
  IntegrationOption,
  IIntegrationOptionWriteDirectionSyncedData,
  IIntegrationOptionWriteDirectionSyncedTestData,
  IntegrationTableData,
} from '@/store/integrations'
import {
  enableIntegrationWriteDirection,
  disableIntegrationWriteDirection,
  testIntegrationWriteDirection,
  syncIntegrationWriteDirection,
} from '@api/index'
import type { Notification } from '@/store/notification'
import type { SelectedIntegration } from '@/components/onboarding/SelectIntegration.vue'
import OnboardingPanel from '@/components/onboarding/OnboardingPanel.vue'
import OnboardingPill from '@/components/onboarding/OnboardingPill.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'
import type { Account } from '@/store'
import Loading from '@/components/tools/Loading.vue'
import type { Clearable, WriteDirectionTestStatus } from '@/helpers/interfaces'

export const INTEGRATION_WRITE_DIRECTION_TEST_FORBIDDEN =
  'INTEGRATION_WRITE_DIRECTION_TEST_FORBIDDEN' as const
export const INTEGRATION_WRITE_DIRECTION_TEST_ERROR =
  'INTEGRATION_WRITE_DIRECTION_TEST_ERROR' as const
export const INTEGRATION_WRITE_DIRECTION_NOT_ENABLED =
  'INTEGRATION_WRITE_DIRECTION_NOT_ENABLED' as const
export const INTEGRATION_WRITE_DIRECTION_SYNC_ONGOING =
  'INTEGRATION_WRITE_DIRECTION_SYNC_ONGOING' as const

interface TestConnection {
  status: WriteDirectionTestStatus | undefined
  errorCode?: string
  loading: boolean
  date: Date | string
}

interface SyncHistory {
  date: Clearable<Date>
  count: Clearable<number>
  loading: boolean
  confirm: boolean
  errorCode?: string
}

export default defineComponent({
  name: 'EmailDataSyncView',
  mixins: [Utils, IntegrationsMixin],
  components: {
    OnboardingPanel,
    OnboardingPill,
    Loading,
  },
  data() {
    return {
      loading: true,
      syncData: false,
      testConnection: {
        status: undefined,
        errorCode: undefined,
        loading: false,
        date: '',
      },
      syncHistory: {
        date: null,
        count: null,
        loading: false,
        confirm: false,
        errorCode: undefined,
      },
    } as {
      loading: boolean
      syncData: boolean
      testConnection: TestConnection
      syncHistory: SyncHistory
    }
  },
  async created() {
    if (!this.selectedIntegration || !this.selectedIntegrationOptions?.writeDirection) {
      this.navigateBack()
      return
    }

    this.loading = true

    await this.mountHotGlue()
    await this.setIntegrations()
    await this.fetchIntegrationOptions()

    if (!isDemoEnvironment) {
      this.selectedIntegration.writeDirection = this.getIntegrationById(
        this.selectedIntegration.id,
      )?.writeDirection
    }

    const syncStatus = this.selectedIntegration.writeDirection?.status?.status
    const testStatus = this.selectedIntegration.writeDirection?.testStatus
    const syncHistory = this.selectedIntegration.writeDirection?.syncHistory

    if (syncStatus === 'disabled') this.syncData = false
    if (syncStatus === 'active' || syncStatus === 'error') this.syncData = true

    if (testStatus) {
      this.testConnection.date = testStatus.initiatedAt
      this.testConnection.status = testStatus.status
    }

    if (syncHistory) {
      this.syncHistory.confirm = true
      this.syncHistory.date = syncHistory.finishedAt
      this.syncHistory.count = syncHistory.numberOfSyncedImpactActions
    }

    this.loading = false
  },
  computed: {
    integrationList(): IntegrationTableData[] {
      return this.getIntegrationsForTable()
    },
    getIntegrationsForTable(): () => IntegrationTableData[] {
      return this.$store.getters['getIntegrationsForTable']
    },
    getIntegrationById(): (id: string) => SelectedIntegration {
      return this.$store.getters['getIntegrationById']
    },
    integrationOptionList(): IntegrationOption[] {
      return this.$store.getters['getIntegrationOptionList']
    },
    getSelectedIntegrationForDataSync(): SelectedIntegration {
      return this.$store.getters['getSelectedIntegrationForDataSync']
    },
    selectedIntegration: {
      get(): SelectedIntegration {
        return this.getSelectedIntegrationForDataSync
      },
      set(value: SelectedIntegration) {
        this.setSelectedIntegrationForDataSync(value)
      },
    },
    selectedIntegrationOptions(): IntegrationOption | undefined {
      return this.integrationOptionList.find((option) => {
        if (isDemoEnvironment) {
          return `${option.name.toLowerCase()} demo` === this.selectedIntegration.name.toLowerCase()
        } else {
          return option.name.toLowerCase() === this.selectedIntegration.platform.toLowerCase()
        }
      })
    },
    testConnectionTimestamp(): string {
      const date = this.testConnection.date
        ? new Date(this.testConnection.date)
        : new Date().toISOString()
      return formatDateToUTC(date)
    },
    syncHistoryTimestamp(): string {
      return this.syncHistory.date ? formatDateToUTC(new Date(this.syncHistory.date)) : ''
    },
    syncedProperties(): IIntegrationOptionWriteDirectionSyncedData | undefined {
      return this.selectedIntegrationOptions?.writeDirection?.syncedProperties
    },
    syncedTestData(): IIntegrationOptionWriteDirectionSyncedTestData | undefined {
      return this.selectedIntegrationOptions?.writeDirection?.syncedTestData
    },
    INTEGRATION_WRITE_DIRECTION_TEST_FORBIDDEN() {
      return INTEGRATION_WRITE_DIRECTION_TEST_FORBIDDEN
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    hasOngoingHistoricalSync() {
      return (
        this.selectedIntegration.writeDirection?.syncHistory &&
        this.selectedIntegration.writeDirection.syncHistory.finishedAt === null
      )
    },
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }): TranslateResult {
      return this.$t(`EmailDataSyncView.${key}`, params ?? {})
    },
    setIntegrations(): Promise<void> {
      return this.$store.dispatch('setIntegrations')
    },
    fetchIntegrationOptions(): Promise<void> {
      return this.$store.dispatch('fetchIntegrationOptions')
    },
    setSelectedIntegrationForDataSync(integration: SelectedIntegration) {
      return this.$store.commit('setSelectedIntegrationForDataSync', integration)
    },
    navigateBack() {
      if (this.$route.query.from === 'onboarding') {
        this.$router.push({
          path: '/',
          query: { from: 'onboarding' },
        })
      } else if (this.$route.query.from === 'engagement') {
        this.$router.push({
          name: 'Post purchase engagement',
        })
      } else {
        this.$router.push({ name: 'Automations' })
      }
    },
    async handleEmailSync() {
      if (isDemoEnvironment) {
        this.selectedIntegration.writeDirection = {
          status: {
            status: this.syncData ? 'active' : 'disabled',
          },
          testStatus: {
            status: 'success',
            initiatedAt: new Date(),
          },
        }
        this.testConnection.status = 'success'
        this.testConnection.date = new Date()
        this.setSelectedIntegrationForDataSync(this.selectedIntegration)
      } else {
        try {
          let response

          if (this.syncData) {
            response = await enableIntegrationWriteDirection(this.selectedIntegration.id)
          } else {
            response = await disableIntegrationWriteDirection(this.selectedIntegration.id)
          }

          this.selectedIntegration.writeDirection = response.data.writeDirection
          this.testConnection.status = 'success'
          this.testConnection.date = new Date()
          await this.setIntegrations()
        } catch (error) {
          this.syncData = false
          this.testConnection.status = 'error'
          this.testConnection.date = new Date()
          this.testConnection.errorCode = error.response?.data?.message

          this.$store.dispatch('notification/notify', {
            text: this.t(
              `test_connection.error.${
                this.testConnection.errorCode || INTEGRATION_WRITE_DIRECTION_TEST_ERROR
              }`,
            ),
            isError: true,
          } as Notification)
        }
      }
    },
    async handleTestConnection() {
      if (isDemoEnvironment) {
        this.testConnection.loading = true

        setTimeout(() => {
          this.testConnection.status = 'success'
          this.testConnection.date = new Date()
          this.testConnection.loading = false

          this.$store.dispatch('notification/notify', {
            text: this.t(`test_connection.success`, {
              emailProvider: this.capitalizeFirstLetter(this.selectedIntegration.platform),
            }),
            isError: false,
          } as Notification)
        }, 1000)
      } else {
        this.testConnection.loading = true

        try {
          const { data } = await testIntegrationWriteDirection(this.selectedIntegration.id)
          this.testConnection.status = data.status
          this.testConnection.date = data.initiatedAt

          this.$store.dispatch('notification/notify', {
            text: this.t(`test_connection.success`, {
              emailProvider: this.capitalizeFirstLetter(this.selectedIntegration.platform),
            }),
            isError: false,
          } as Notification)
        } catch (error) {
          this.testConnection.status = 'error'
          this.testConnection.date = new Date()
          this.testConnection.errorCode = error.response?.data?.message

          this.$store.dispatch('notification/notify', {
            text: this.t(
              `test_connection.error.${
                this.testConnection.errorCode || INTEGRATION_WRITE_DIRECTION_TEST_ERROR
              }`,
            ),
            isError: true,
          } as Notification)
        } finally {
          this.testConnection.loading = false
        }
      }
    },
    async handleSyncHistory() {
      if (isDemoEnvironment) {
        this.syncHistory.loading = true
        this.syncHistory.date = null

        setTimeout(() => {
          this.syncHistory.date = new Date()
          this.syncHistory.count = 1560
          this.syncHistory.loading = false

          this.selectedIntegration.writeDirection = {
            status: {
              status: this.syncData ? 'active' : 'disabled',
            },
            testStatus: {
              status: 'success',
              initiatedAt: new Date(),
            },
            syncHistory: {
              initiatedAt: new Date(),
              finishedAt: new Date(),
              numberOfSyncedImpactActions: 1560,
            },
          }
        }, 2000)
      } else {
        this.syncHistory.loading = true
        this.syncHistory.errorCode = undefined

        try {
          const { data } = await syncIntegrationWriteDirection(this.selectedIntegration.id)
          this.selectedIntegration.writeDirection = data.writeDirection
          this.syncHistory.date = null
          this.syncHistory.count = null
        } catch (error) {
          this.syncHistory.errorCode = error.response?.data?.message

          if (this.syncHistory.errorCode === INTEGRATION_WRITE_DIRECTION_NOT_ENABLED) {
            this.syncData = false
          }

          this.$store.dispatch('notification/notify', {
            text: this.t(`historical_data.error.${this.syncHistory.errorCode || 'general'}`),
            isError: true,
          } as Notification)
        } finally {
          this.syncHistory.loading = false
        }
      }
    },
    async navigateToNextStep() {
      await this.handleTestConnection()
      this.loading = false
    },
    handleIntegrationPopupClose() {
      this.loading = false
    },
    handleIntegrationPopupOpen() {
      this.loading = false
    },
    handleIntegrationUpdate() {
      if (this.selectedIntegrationOptions) {
        this.loading = true
        this.setupIntegration(
          this.account?.accountId,
          this.selectedIntegrationOptions,
          this.navigateToNextStep,
          this.handleIntegrationPopupClose,
          this.handleIntegrationPopupOpen,
        )
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.email-data-sync-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 35px;
  height: 100%;
  min-height: 100vh;
  background: url('@/assets/backgrounds/full-width-popup-background.svg') no-repeat top left/cover
    #ffffff;
  padding: 20px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 80px 40px;
  }
}

:deep(.onboarding-panel) {
  padding: 20px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 44px 32px;
  }
}

:deep(.onboarding-panel__title) {
  padding-right: 60px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: 40px;
  }
}

:deep(.onboarding-panel__description) {
  max-width: 100%;
  margin-bottom: 24px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: 20px;
  }
}

.close-button {
  position: absolute;
  right: 25px;
  top: 20px;
  width: 30px;
  height: 30px;
}

.email-data-sync-historical {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 24px 36px;
  }

  &.disabled {
    color: var(--gray-light-A9);
  }

  :deep(.onboarding-panel__title) {
    font-size: 24px;
    margin-bottom: 0;
  }

  :deep(.onboarding-panel__description) {
    margin-bottom: 16px;
    font-size: 18px;
  }
}

.timestamp {
  font-weight: 700;
  margin: 24px 0 0;
  color: var(--ui-black);
}

.email-data-sync-toggle {
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid var(--gray-light-CC);
  transition: all 100ms ease-in-out;

  &.active {
    border: 2px solid var(--ui-green);
    background-color: rgba(71, 209, 143, 0.2);
  }

  &.error {
    border: 2px solid #ff2424;
    background-color: rgba(255, 36, 36, 0.2);
  }

  &__description,
  &__disclaimer {
    font-weight: 700;
    margin-bottom: 0;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: 18px;
    }
  }

  &__switch {
    :deep(.v-label) {
      font-size: 14px;
      font-weight: 700;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        font-size: 24px;
      }
    }
  }

  &__disclaimer {
    color: var(--ui-green);
  }
}

.email-data-sync-list {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--gray-light);

  &__options {
    margin-left: 16px;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    margin: 12px 0;
  }

  &__link {
    display: inline-block;
    margin-top: 32px;
    font-weight: 600;
    color: #0017b4;
  }
}

.list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      align-items: center;
      gap: 16px;
    }

    :deep(.onboarding-pill__icon) {
      font-size: 18px;
    }

    :deep(.onboarding-pill__content) {
      font-size: 14px;
    }

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__item-text {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.email-data-sync-test {
  &__disclaimer {
    font-size: 18px;
    font-weight: 700;
  }
}

.email-data-sync-test__connection {
  &--success {
    color: var(--ui-green);
  }

  &--error {
    color: #ff2424;
  }
}

.error-alert {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 16px 0 0;
  border-radius: 4px;
  border: 1px solid #ff2424;
  background: rgba(255, 36, 36, 0.2);
  cursor: pointer;
}

.success-alert {
  display: flex;
  padding: 4px 8px;
  gap: 8px;
  margin: 24px 0 0;
  font-size: 20px;
  font-weight: 700;
  color: var(--ui-black);
  background-color: rgba(71, 209, 143, 0.2);
}
</style>
